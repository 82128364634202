
import { defineComponent, computed } from "vue";

import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { Actions } from "@/store/enums/StoreEnums";
import ApiService from "@/core/services/ApiService";
import store from "@/store";
import Swal from "sweetalert2/dist/sweetalert2.min.js";

export default defineComponent({
  name: "kt-user-menu",
  components: {},
  methods: {
    resetPassword() {
      ApiService.postNoAxios("/User/RedefinirPassword", {email: store.getters.currentAuthUser.email}).then(() => {
        Swal.fire({
          title: "Sucesso",
          text: "Foi enviado um email com as instruções para redefinir a sua password",
          icon: "success",
          confirmButtonText: "Ok"
        })
        }).catch(() => {
          Swal.fire({
            title: "Erro",
            text: "Ocorreu um erro ao tentar enviar o email",
            icon: "error",
            confirmButtonText: "Ok"
          })
        });
    },
    
  },
  setup() {
    const router = useRouter();

    const store = useStore();

   
    const signOut = () => {
      store
        .dispatch(Actions.LOGOUT)
        .then(() => router.push({ name: "sign-in" }));
    };


    return {
      signOut,
    };
  },
});
