import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "menu menu-sub menu-sub-dropdown menu-column w-250px w-lg-275px",
  "data-kt-menu": "true"
}
const _hoisted_2 = {
  class: "d-flex flex-column bgi-no-repeat rounded-top",
  style: {"background-image":"url('media/misc/pattern-1.jpg')"}
}
const _hoisted_3 = { class: "text-white fw-bold px-9 mt-10 mb-6" }
const _hoisted_4 = { class: "fs-6 opacity-75 ps-3" }
const _hoisted_5 = { class: "tab-content" }
const _hoisted_6 = {
  class: "",
  id: "",
  role: "tabpanel"
}
const _hoisted_7 = { class: "scroll-y mh-325px" }
const _hoisted_8 = { class: "badge-light fs-7" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h3", _hoisted_3, [
        _createTextVNode(" Notificações "),
        _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.totalNotifications), 1)
      ])
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("div", _hoisted_7, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.notifications, (item, index) => {
            return (_openBlock(), _createElementBlock("div", {
              key: index,
              class: "text-center py-2"
            }, [
              _createElementVNode("span", _hoisted_8, _toDisplayString(item), 1)
            ]))
          }), 128))
        ])
      ])
    ])
  ]))
}