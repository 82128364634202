
import { defineComponent } from "vue";

import { toolbarWidthFluid } from "@/core/helpers/config";

export default defineComponent({
  name: "KToolbar",
  props: {
    breadcrumbs: Array,
    title: String,
  },
  components: {},
  data() {
    return {
      filterBody: String,
    };
  },
  setup() {
    function onFilter() {
      console.log("TO IMPLEMENT");
    }

    return {
      toolbarWidthFluid,
      onFilter,
    };
  },
});
